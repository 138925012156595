<template>
	<div class="delivery-record-table position-relative w-100 h-100 px-4 py-3 d-flex flex-column overflow-hidden">
		<table-summary title="Delivery Record" filter="recordNo" sort="receivedAt"
		               v-bind:order="-1"
		               v-bind:fields="fields"
		               v-bind:data-array="deliveryRecords"
		               v-on:add-new="addRecord"
		               v-on:row-clicked="navigate($event)"></table-summary>
	</div>
</template>

<script>
import TableSummary from "@/components/functional/table/TableSummary";
import {format} from "date-fns";

export default {
	name: "RecordTable",
	
	components: {
		TableSummary,
	},
	
	data() {
		return {
			fields: [
				{ display: "Record No.", key: "recordNo", classBinding: ["f-3"] },
				{ display: "PO No.", key: "poNo", classBinding: ["f-3"] },
				{ display: "Supplier", key: "supplier", classBinding: ["f-5"] },
				{ display: "Received", key: "itemsReceived", classBinding: [] },
				{ display: "Accepted", key: "itemsAccepted", classBinding: [] },
				{ display: "Rejected", key: "itemsRejected", classBinding: [] },
				{ display: "Total", key: "receivedTotal", classBinding: ["f-2"] },
				{ display: "Received At", key: "receivedAt", classBinding: ["f-3"] },
				{ display: "Received By", key: "receivedBy", classBinding: ["f-3"] },
			],
		}
	},
	
	computed: {
		deliveryRecords() {
			return this.$store.state.contents.deliveryRecords.map(record => {
				const obj = {...record};
				const supplier = this.$store.state.contents.suppliers.find(({ _id }) => _id === record.supplier);
				const po = this.$store.state.contents.purchaseOrders.find(({ _id }) => _id === record.purchaseOrder);
				const receivedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === record.receivedBy);
				obj.supplier = supplier ? `${supplier.name} (${supplier.supplierNo})` : "N/A";
				obj.receivedBy = receivedBy ? `${receivedBy.preferredName} ${receivedBy.lastName}` : "N/A";
				obj.poNo = po ? po.poNo : "N/A";
				obj.receivedAt = record.receivedAt ? format(new Date(record.receivedAt), "yyyy-MM-dd") : "N/A";
				obj.itemsReceived = record.itemsReceived.length; // type Number
				obj.itemsAccepted = record.itemsAccepted.length; // type Number
				obj.itemsRejected = record.itemsRejected.length; // type Number
				return obj;
			});
		}
	},
	
	methods: {
		async addRecord() {
			await this.$router.push("/supplier/record/add");
		},
		
		async navigate(dataObj) {
			await this.$router.push(`/supplier/record/${dataObj._id}`);
		}
	}
}
</script>

<style lang="scss" scoped>

</style>